exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-concurso-brasil-tsx": () => import("./../../../src/pages/concurso-brasil.tsx" /* webpackChunkName: "component---src-pages-concurso-brasil-tsx" */),
  "component---src-pages-concurso-cine-tsx": () => import("./../../../src/pages/concurso-cine.tsx" /* webpackChunkName: "component---src-pages-concurso-cine-tsx" */),
  "component---src-pages-concursos-tsx": () => import("./../../../src/pages/concursos.tsx" /* webpackChunkName: "component---src-pages-concursos-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-contest-rules-tsx": () => import("./../../../src/pages/contest-rules.tsx" /* webpackChunkName: "component---src-pages-contest-rules-tsx" */),
  "component---src-pages-curso-taller-empanada-hoja-tsx": () => import("./../../../src/pages/curso-taller-empanada-hoja.tsx" /* webpackChunkName: "component---src-pages-curso-taller-empanada-hoja-tsx" */),
  "component---src-pages-history-tsx": () => import("./../../../src/pages/history.tsx" /* webpackChunkName: "component---src-pages-history-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pro-contact-tsx": () => import("./../../../src/pages/pro/contact.tsx" /* webpackChunkName: "component---src-pages-pro-contact-tsx" */),
  "component---src-pages-pro-index-tsx": () => import("./../../../src/pages/pro/index.tsx" /* webpackChunkName: "component---src-pages-pro-index-tsx" */),
  "component---src-pages-pro-school-tsx": () => import("./../../../src/pages/pro/school.tsx" /* webpackChunkName: "component---src-pages-pro-school-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-templates-contest-rules-[id]-tsx": () => import("./../../../src/templates/contest-rules/[id].tsx" /* webpackChunkName: "component---src-templates-contest-rules-[id]-tsx" */),
  "component---src-templates-pro-recipe-[id]-tsx": () => import("./../../../src/templates/pro/recipe/[id].tsx" /* webpackChunkName: "component---src-templates-pro-recipe-[id]-tsx" */),
  "component---src-templates-product-[id]-tsx": () => import("./../../../src/templates/product/[id].tsx" /* webpackChunkName: "component---src-templates-product-[id]-tsx" */),
  "component---src-templates-product-category-tsx": () => import("./../../../src/templates/product-category.tsx" /* webpackChunkName: "component---src-templates-product-category-tsx" */),
  "component---src-templates-recipe-[id]-tsx": () => import("./../../../src/templates/recipe/[id].tsx" /* webpackChunkName: "component---src-templates-recipe-[id]-tsx" */),
  "component---src-templates-recipes-professional-tsx": () => import("./../../../src/templates/recipes-professional.tsx" /* webpackChunkName: "component---src-templates-recipes-professional-tsx" */),
  "component---src-templates-recipes-tsx": () => import("./../../../src/templates/recipes.tsx" /* webpackChunkName: "component---src-templates-recipes-tsx" */)
}

